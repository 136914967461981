import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IdNameModel } from 'src/app/common/service/topativity.classes';
import { ConstantKey, LoginServices } from './core-helper.classes';
declare var bootstrap: any;

export enum ServiceColorCodeId {
  NotConfirmedColorId = 1,
  ConfirmedColorId = 2,
  BilledColorId = 3,
  BilledIssueColorId = 4,
  DeniedColorId = 5,
  ChangeServiceColorId = 6,
  PaidColorId = 7,
  RefundColorId = 8,
  DarkGreenPaidColorId = 9,
  VoidColorId = 10
}
export enum departmentCodeId {
  ThirdPartyNegotiations = 1,
  RefundRequests = 2,
  RevenueCycleManagement = 3,
  MedicalRecords = 4,
  VerificationofBenefits = 5,
  UtilizationReview = 6,
  Billing = 7,
  PaymentPosting = 8,
  Credentialing = 9,
  AccountManagement = 10,
  Facility = 11,
  OPs = 12,
}
@Injectable({
  providedIn: 'root'
})
export class CoreHelperService {

  // used for file upload validation
  imageSize = 3;
  allowedExtension = ["png", "jpeg", "jpg", "gif"];
  previousStartWeek: any;

  //departmnet constnt name
  VOB = 'verification of benefits';
  UR = 'utilization review';
  Billing = 'billing';
  RCM = 'revenue cycle management';
  MedicalRecord = "medical records";
  Payment = "payment posting";
  //PageSize

  pageSize = 200;
  tenPageSize = 10;

  //custom color of donut chart
  chartDefaultV4Colors: string[] = [
    "#b8f0fe",
    "#98c6dc",
    "#02b8e3",
    "#4297bf",
    "#9bc2e6",
  ];

  isShowHeader: boolean | null = true;
  frontOfficeLogin: boolean = true;
  headerTitle = new Subject<string>();
  callHeader = new Subject<boolean>();
  private viewWarehouseDetails = new BehaviorSubject<Object>(false);
  warehouseDetailsData = this.viewWarehouseDetails.asObservable();

  private sideBarLinkActive = new BehaviorSubject<string>("");
  sidebarActiveModuleName = this.sideBarLinkActive.asObservable();

  LoginServiceInfo: LoginServices;

  public correctedClaimReasonList: Array<{ text: string; value: number }> = [
    { text: "Incorrect DOS billed", value: 1 },
    { text: "Incorrect LOC billed", value: 2 },
    { text: "Coding", value: 3 },
    { text: "Patient demographics updated (policy ID#, policy GRP#, patient DOB, etc.)", value: 4 },
    { text: "Auth# updated", value: 5 },
    { text: "Interim charges combined to reflect admit through DC", value: 6 },
    { text: "Incorrect service location", value: 7 },
    { text: "Incorrect provider", value: 8 },
    { text: "Misc (not listed elsewhere)", value: 9 },
  ];

  public voidClaimReasonList: Array<{ text: string; value: number }> = [
    { text: "Claim/ service billed in error", value: 1 },
    { text: "Misc (not listed elsewhere)", value: 2 },
  ];

  constructor(private titleService: Title, private route: Router) { }

  public passHeaderTitle(headerTitle: string) {
    this.headerTitle.next(headerTitle);
  }
  public updateTabData(headerTitle: string) {
    this.headerTitle.next(headerTitle);
  }
  public callHeaderNotification() {
    this.callHeader.next(true);
  }
  //patterns
  Passwordpattern: string = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$";
  EmailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$";
  SpacePattern: string = "^[a-zA-Z0-9_-]+$";
  //size
  fileSize: any = "10485760";

  //
  getTitle() {
    return localStorage.getItem('title')
  }

  setRootData = () => {
    this.viewWarehouseDetails.next(true);
  }

  setSidebarModuleName = (sidebarModuleName: string) => {
    this.sideBarLinkActive.next(sidebarModuleName);
  }

  setBrowserTabTitle = (title: string) => {
    this.titleService.setTitle(title);
  }

  isNullOrUndefined<T>(tObj: T): boolean {
    return tObj === null || tObj === undefined;
  }

  getPreviosFouryear() {
    let dateArr = new Array<IdNameModel>();
    let d = new Date();
    for (let index = 0; index < 4; index++) {
      let arr = new IdNameModel();
      arr.code = d.getFullYear() - index;
      arr.id = index;
      dateArr.push(arr)
    }
    return dateArr;
  }

  isStringEmptyOrWhitespace(stringToParse: string) {
    return this.isNullOrUndefined(stringToParse) || stringToParse.trim() === '' || stringToParse.trim() === 'null' || stringToParse.trim() === 'undefined';
  }

  isStringNullOrUndefined(stringToParse: string) {
    return this.isNullOrUndefined(stringToParse) || stringToParse === '';
  }
  isArrayEmpty<T>(tArr: T[]): boolean {
    return this.isNullOrUndefined(tArr) || tArr.length <= 0;
  }

  getShortedText = (text: string, limit: number) => {
    if (!this.isNullOrUndefined(limit)) {
      return text.length > limit ? text.substring(0, limit) + '...' : text;
    }
    return text.length > ConstantKey.STRINGLIMITSHORT ? text.substring(0, ConstantKey.STRINGLIMITSHORT) + '...' : text;
  }

  removeAllWhiteSpaces = (text: string) => {
    return text.replace(/\s/g, '');
  }

  snakeCaseToLowerCase = (text: string) => {
    return text.replace(/\_/g, '');
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls.length;
        return totalSelected >= min ? null : { notSelected: true };
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }
  maxSelectedCheckboxes(max: number) {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const totalSelected = formArray.controls.length;
        return totalSelected > max ? { maxInvalid: true } : null;
      }

      throw new Error('formArray is not an instance of FormArray');
    };

    return validator;
  }

  getFacilityName = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.facilityName;
    }
    else {
      return null;
    }
  }

  getUserId = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.userId;
    }
    else {
      return null;
    }
  }

  getUserFullName = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return (detail.firstName + ' ' + detail.lastName);
    }
    else {
      return null;
    }
  }

  getOpsDepartmentId = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.departmentId;
    }
    else {
      return null;
    }
  }

  getUserDomain(){
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.domainId;
    }
    else {
      return null;
    }
  }

  getFacilityId = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.domainId == 0 ? 0 : detail.facilityId;
    }
    else {
      return null;
    }
  }
  isInternalTeamLead = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return (detail.domainId == 0 && detail.roleName.toLowerCase().includes("lead")) ? true : false
    }
    else {
      return false;
    }
  }
  isInternalOps = () => {
    var userData = localStorage.getItem('UserDetail');
    var returnStatus = false;
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      if (detail == null && detail == undefined) {
        return returnStatus;
      }
      if ((detail.domainId == 0 && (
        detail.roleName.toLowerCase().includes("director") ||
        detail.roleName.toLowerCase().includes("super") ||
        detail.roleName.toLowerCase().includes("executive") ||
        detail.roleName.toLowerCase().includes("ops") ||
        detail.roleName.toLowerCase().includes("vp")))) {
        returnStatus = true;
      }
    }
    return returnStatus;
  }
  isInternalManager = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return (detail.domainId == 0 && detail.roleName.toLowerCase().includes("manager")) ? true : false
    }
    else {
      return false;
    }
  }
  isInternalSpecialist = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return (detail.domainId == 0 && detail.roleName.toLowerCase().includes("specialist")) ? true : false
    }
    else {
      return false;
    }
  }

  getProfilePath = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.profileImage;
    }
    else {
      return null;
    }
  }

  getProfileName = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      var detail = JSON.parse(userData);
      return detail.firstName + " " + detail.lastName;
    }
    else {
      return null;
    }
  }

  isHideFacility = () => {
    return this.getFacilityId() > 0 ? true : false;
  }

  isTeamLead() {
    let user = this.getLoggedInUserDetail();
    if (this.isNullOrUndefined(user)) {
      return false;
    }
    else {
      return user.roleName.toLowerCase() == 'lead'
    }
  }

  isSpecialist() {
    let user = this.getLoggedInUserDetail();
    if (this.isNullOrUndefined(user)) {
      return false;
    }
    else {
      return user.roleName.toLowerCase() == 'specialist'
    }
  }

  getLoggedInUserDomain() {
    let user = this.getLoggedInUserDetail();
    if (this.isNullOrUndefined(user)) {
      return -1;
    }
    else {
      return user.domainId;
    }
  }
  getLoggedInUserDetail = () => {
    var userData = localStorage.getItem('UserDetail');
    if (userData !== null && userData !== undefined && userData !== "") {
      return JSON.parse(userData);
    }
    else {
      return null;
    }
  }


  checkIsAgreedAgreement = () => {
    var userData = localStorage.getItem('UserDetailNew');
    if (userData !== null && userData !== undefined && userData !== "") {
      let data = JSON.parse(userData);
      return data.userMasterDetail.isAgreed
    }
    else {
      return 0;
    }
  }

  getUserDetailNew = () => {
    var userData = localStorage.getItem('UserDetailNew');
    if (userData !== null && userData !== undefined && userData !== "") {
      let data = JSON.parse(userData);
      return data
    }
    else {
      return 0;
    }
  }

  clearLocalStorage() {
    localStorage.clear();
  }

   patternPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value) {

        const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
        const valid = regex.test(control.value);
        if (!valid) {
          return { invalidPassword: true };
        }
      }
    };
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  MustNotMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      // set error on matchingControl if validation fails
      if (control.value == matchingControl.value) {
        matchingControl.setErrors({ mustNotMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  convertToTitleCase(str: any) {
    str = str.toLowerCase();
    str = str.split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    // Step 4. Return the output
    return str.join(' ');
  }
  getUrlValidation(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value) {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
        const valid = regex.test(control.value);
        if (!valid) {
          return { invalidUrl: true };
        }
      }
    };
  }

  getNameValidation(): ValidatorFn {
    return (control: AbstractControl): any => {
      if (control.value) {
        const regex = new RegExp('^[a-zA-Z \-\']+')
        const valid = regex.test(control.value);
        if (!valid) {
          return { "invalidName": true };
        }
      }
    };

  }

  doNotAllowNumber() {
    return '^[a-zA-Z \-\']+';
  }
  getPhoneValidation() {
    return "^[0-9]*$";
  }
  getPhoneValidations() {
    return /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
  }

  getUtcDate(currentDate: any) {
    return new Date(moment.utc(moment.utc(currentDate).local().format('MM/DD/YYYY')).local().toString())
  }
  getUtcDateTime(currentDate: any) {
    return new Date(moment(moment.utc(currentDate).local().format('MM/DD/YYYY hh:mm a')).toISOString());
  }
  getUtcDateOnly(currentDate: any) {
    return moment.utc(moment.utc(currentDate).local().format('MM/DD/YYYY')).format('L');
  }

  getUtcDateOnlyWithFormat(currentDate: any, dateFormat: string) {
    return moment.utc(moment.utc(currentDate).local(false)).format(dateFormat);
  }

  public FormatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  headerUserName = new Subject<string>();
  public passHeaderUserName(headerTitle: string) {
    this.headerUserName.next(headerTitle);
  }


  dropDownPositionUpdate(elemArray: any) {
    if (!!bootstrap) {
      for (const index in elemArray) {
        var dropdown = new bootstrap.Dropdown(elemArray[index], {
          popperConfig: function (defaultBsPopperConfig: any) {
            const newPopperConfig = {
              ...defaultBsPopperConfig,
              strategy: 'fixed',
            };
            return newPopperConfig;
          }
        })
      }
    }
  }

  downloadFile(url, fileName) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  // Use for clear reactive form validation

  public removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      if (this.isNullOrUndefined(form.get(key).value) || form.get(key).value === '' || form.get(key).value === "") {
        form.get(key).clearValidators();
        form.get(key).updateValueAndValidity();
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, (month + 1), 0).getDate();
  }
  getCurrentWeek() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('isoWeek');
    var weekEnd = currentDate.clone().endOf('isoWeek');
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("MM/DD/YYYY"));
    }
  }

  getLastWeek() {

    var currentDate = moment();
    var weekStart = moment().startOf('week').isoWeekday(1);
    var weekEnd = moment().startOf('week').isoWeekday(0);;
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format("MM/DD/YYYY"));
    }
    return days;
  }

  getWeekStartDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('isoWeek');
    return weekStart.toDate();
  }

  getWeekEndDate() {
    var currentDate = moment();

    var weekStart = currentDate.clone().endOf('isoWeek');
    return weekStart.toDate();
  }

  getMonthStartDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('month');
    return weekStart.toDate();
  }

  getMonthEndDate() {
    var currentDate = moment();

    var weekStart = currentDate.clone().endOf('month');
    return weekStart.toDate();
  }

  getYearStartDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('year');
    return weekStart.toDate();
  }

  getYearEndDate() {
    var currentDate = moment();
    var weekStart = currentDate.clone().endOf('year');
    return weekStart.toDate();
  }

  getLastWeekStartDate() {
    var currentDate = moment();
    // Use startOf 'isoWeek' to show week as MON to SUN
    // Use startOf 'week' to show week as SUN to SAT
    var weekFirstDay = currentDate.subtract(1, 'weeks').startOf('isoWeek');
    return weekFirstDay.toDate();
  }
  getLastWeekEndDate() {
    var currentDate = moment();
    // Use startOf 'isoWeek' to show week as MON to SUN
    // Use startOf 'week' to show week as SUN to SAT
    var weekLastDay = currentDate.subtract(1, 'weeks').endOf('isoWeek');
    return weekLastDay.toDate();
  }
  getLastMonthStartDate() {
    var currentDate = moment();
    var monthFirstDay = currentDate.subtract(1, 'months').startOf('month');
    return monthFirstDay.toDate();
  }
  getLastMonthEndDate() {
    var currentDate = moment();
    var monthLastDay = currentDate.subtract(1, 'months').endOf('month');
    return monthLastDay.toDate();
  }
  getLastYearStartDate() {
    var currentDate = moment();
    var yearFirstDay = currentDate.subtract(1, 'year').startOf('year');
    return yearFirstDay.toDate();
  }
  getLastYearEndDate() {
    var currentDate = moment();
    var yearLastDay = currentDate.subtract(1, 'year').endOf('year');
    return yearLastDay.toDate();
  }
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  public SetLoginServiceInformation(loginInfo: LoginServices){
    this.LoginServiceInfo = loginInfo;
  }

  public GetLoginServiceInformation(){
    return this.LoginServiceInfo;
  }
}
